import { CrudState } from './types'

const getCrudModeSelectors = (state: CrudState) => {
  return {
    isCreate: state.open === 'create',
    isUpdate: state.open === 'update',
    isDelete: state.open === 'delete',
    isView: state.open === 'view',
    isOpen: state.open !== null,
  }
}

const capitalizeCurrent = (current?: string) => {
  if (current === undefined) return 'Item'
  return current.charAt(0).toUpperCase() + current.slice(1).toLowerCase()
}

const retrieveBaseMessage = (state: CrudState) => {
  const { isCreate, isUpdate, isView, isDelete } = getCrudModeSelectors(state)
  const textFromCurrentProperty = capitalizeCurrent(state.name)
  if (isCreate) return `Create ${textFromCurrentProperty}`
  if (isUpdate) return `Edit ${textFromCurrentProperty}`
  if (isView) return `View ${textFromCurrentProperty}`
  if (isDelete) return `Delete ${textFromCurrentProperty}`
  return `Current ${textFromCurrentProperty}`
}

const retrieveLoadingText = (state: CrudState) => {
  const { isCreate, isUpdate, isView, isDelete, isOpen } = getCrudModeSelectors(state)
  const textFromCurrentProperty = capitalizeCurrent(state.name)
  if (!isOpen) return `Fetching ${textFromCurrentProperty}s`
  if (isCreate) return `Creating ${textFromCurrentProperty}`
  if (isUpdate) return `Updating ${textFromCurrentProperty}`
  if (isView) return `Viewing ${textFromCurrentProperty}`
  if (isDelete) return `Deleting ${textFromCurrentProperty}`
  return `Loading ${textFromCurrentProperty}`
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const contextSelectors = (state: CrudState) => {
  return {
    modeSelector: getCrudModeSelectors(state),
    baseModalMessage: retrieveBaseMessage(state),
    loadingText: retrieveLoadingText(state),
    isLoading: state.loading,
    loadings: {
      isCreate: state.open === 'create' && state.loading,
      isUpdate: state.open === 'update' && state.loading,
      isDelete: state.open === 'delete' && state.loading,
      isView: state.open === 'view' && state.loading,
      isGet: state.open !== null && state.loading,
    },

    currentItem: state.data
      ? {
          id: state.id,
          item: state.data,
        }
      : undefined,
    api: {
      list: state.list,
      meta: state.meta,
    },
  }
}
